@import "../../../../../../variables";

.InteractionItem {
  display: grid;
  grid-template-columns: auto 20px;
  grid-gap: 25px;
  align-items: center;
  border-radius: 6px;
  padding: 10px 12px;
  margin-bottom: 10px;
  background-color: $light-gray-filler;
  transition: linear .3s;

  &:hover, &_active {
    cursor: pointer;
    background-color: #e8e8ea;
  }

  &:hover .InteractionItem_TrashIcon {
    opacity: 1;
  }


  &_Content {
    &_Header {
      display: grid;
      grid-template-columns: 20px auto;
      grid-gap: 10px;
      margin-bottom: 3px;

      &_Icon {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      &_SubjectAndDate {
        display: flex;
        justify-content: space-between;

        &--grey {
          color: #8A8A8A;
        }
      }
    }

    &_Body {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #909CA8;

      &_Icon {
        margin-left: 10px;
        font-size: 17px;
      }
    }
  }

  &_TrashIcon {
    opacity: 0;
    transition: opacity .2s linear;

    svg {
      color: #909CA8;
    }
  }
}
