@import "../../../variables";

.WelcomeBar {

  background-color: $lighterer-green;
  margin: 20px auto 36px auto;
  padding: 24px;
  white-space: pre-line;
  border-radius: 6px;

  span {
    font-size: 13px;
    color: $dark-text;
    margin: 0;
    text-align: center;
    font-family: $inter-font;
  }


}
